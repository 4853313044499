<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      sort-by="position"
      class="elevation-1"
      no-data-text="Aucune Année Scolaire"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title> Années scolaires </v-toolbar-title>
          <v-spacer />
          <v-btn
            class="primary mb-2"
            @click="create()"
          >
            Ajouter une Année Scolaire
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-icon
                v-on="on"
                @click.stop
              >
                more_vert
              </v-icon>
            </template>
            <v-list>
              <v-list-item @click="edit(item)">
                <v-list-item-title>
                  Modifier
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="suppr(item)">
                <v-list-item-title>
                  Supprimer
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
      <template v-slot:item.dates_off="{ item }">
        {{ item.date_debut | formatDate }} - {{ item.date_fin | formatDate }}
      </template>
      <template v-slot:item.dates_saisie="{ item }">
        {{ item.date_saisie_debut | formatDate }} - {{ item.date_saisie_fin | formatDate }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ListAnneeScolaireComponent',
  props: {
  },
  data() {
    return {
      headers: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Libelle', value: 'libelle', sortable: true },
        { text: 'Dates officielles', value: 'dates_off', sortable: true },
        { text: 'Dates de saisie', value: 'dates_saisie', sortable: true },
      ],
    };
  },
  computed: {
    ...mapGetters('parametrages/anneeScolaire', {
      items: 'getAnneesScolaires',
    }),
  },
  methods: {
    edit(item) {
      this.$emit('edit', item);
    },
    create(item) {
      this.$emit('create', item);
    },
    suppr(item) {
      this.$emit('delete', item);
    },
  },
};
</script>