<template>
  <div>
    <ListAnneeScolaireComponent
      @edit="item => { modeIsCreate = false; editedAnnee = item; }"
      @create="() => { modeIsCreate = true; editedAnnee = { libelle: '', date_debut: '', date_fin: '', date_saisie_debut: '', date_saisie_fin: '' }; }"
      @delete="item => deletedAnnee = item"
    />
    <EditAnneeScolaireComponent
      :annee="editedAnnee"
      @cancel="editedAnnee = undefined"
      @save="result => { save(result); editedAnnee = undefined; }"
    />
    <ConfirmedModal
      v-if="deletedAnnee"
      sentence="Supprimer cette Année Scolaire ?"
      btn-action="Supprimer"
      @close="deletedAnnee = undefined"
      @confirmed="() => { deleteAnneeScolaire(deletedAnnee); deletedAnnee = undefined; }"
    />
  </div>
</template>

<script>
import ListAnneeScolaireComponent from '../../components/parametrage/anneeScolaire/ListAnneeScolaireComponent';
import EditAnneeScolaireComponent from '../../components/parametrage/anneeScolaire/EditAnneeScolaireComponent';
import ConfirmedModal from '../../components/reusableComponents/confirmedModal';
import { mapActions } from 'vuex';

export default {
  components: {
    ListAnneeScolaireComponent,
    EditAnneeScolaireComponent,
    ConfirmedModal,
  },
  data() {
    return {
      editedAnnee: undefined,
      deletedAnnee: undefined,
      modeIsCreate: false,
    };
  },
  created() {
    this.loadFilterAnneesScolaires();
  },
  methods: {
    ...mapActions('parametrages/anneeScolaire', [
      'loadFilterAnneesScolaires',
      'createAnneeScolaire',
      'updateAnneeScolaire',
      'deleteAnneeScolaire',
    ]),
    save(item) {
      if (this.modeIsCreate) {
        this.createAnneeScolaire(item);
      } else {
        this.updateAnneeScolaire(item);
      }
    },
  },
};
</script>