var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"position","no-data-text":"Aucune Année Scolaire"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Années scolaires ")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary mb-2",on:{"click":function($event){return _vm.create()}}},[_vm._v(" Ajouter une Année Scolaire ")])],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){$event.stopPropagation();}}},on),[_vm._v(" more_vert ")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.edit(item)}}},[_c('v-list-item-title',[_vm._v(" Modifier ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.suppr(item)}}},[_c('v-list-item-title',[_vm._v(" Supprimer ")])],1)],1)],1)],1)]}},{key:"item.dates_off",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_debut))+" - "+_vm._s(_vm._f("formatDate")(item.date_fin))+" ")]}},{key:"item.dates_saisie",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date_saisie_debut))+" - "+_vm._s(_vm._f("formatDate")(item.date_saisie_fin))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }