<template>
  <v-dialog
    v-if="dialog"
    v-model="dialog"
    max-width="600px"
  >
    <v-card class="pa-5">
      <v-card-title>
        <span class="headline"> Année Scolaire </span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="valid">
          <v-row>
            <v-col
              key="libelle"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model.trim="editedAnnee.libelle"
                v-validate.immediate="'required|min:3|max:50'"
                label="Libellé *"
                data-vv-name="libelle"
                :error-messages="errors.collect('libelle')"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            />
            <v-col
              key="date_debut"
              cols="12"
              sm="6"
            >
              <DatePicker
                label="Date de début"
                :date="editedAnnee.date_debut"
                :readonly="false"
                :required="true"
                :before="editedAnnee.date_fin"
                @changeDate="date => editedAnnee.date_debut = date"
              />
            </v-col>
            <v-col
              key="date_fin"
              cols="12"
              sm="6"
            >
              <DatePicker
                label="Date de fin"
                :date="editedAnnee.date_fin"
                :readonly="false"
                :required="true"
                :after="editedAnnee.date_debut"
                @changeDate="date => editedAnnee.date_fin = date"
              />
            </v-col>
            <v-col
              key="date_saisie_debut"
              cols="12"
              sm="6"
            >
              <DatePicker
                label="Date de début de saisie"
                :date="editedAnnee.date_saisie_debut"
                :readonly="false"
                :required="true"
                :before="editedAnnee.date_saisie_fin"
                @changeDate="date => editedAnnee.date_saisie_debut = date"
              />
            </v-col>
            <v-col
              key="date_saisie_fin"
              cols="12"
              sm="6"
            >
              <DatePicker
                label="Date de fin de saisie"
                :date="editedAnnee.date_saisie_fin"
                :readonly="false"
                :required="true"
                :after="editedAnnee.date_saisie_debut"
                @changeDate="date => editedAnnee.date_saisie_fin = date"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="primary"
          @click="dialog=false"
        >
          Annuler
        </v-btn>
        <v-btn
          text
          :disabled="!valid || dontHaveChange() || !hasDates()"
          @click="save()"
        >
          Enregister
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '../../partials/DatePicker';

export default {
  name: 'EditAnneeScolaireComponent',
  components: {
    DatePicker,
  },
  props: {
    annee: { type: Object, default: undefined },
  },
  data() {
    return {
      editedAnnee: null,
      dialog: false,
      valid: true,
    };
  },
  computed: {
    conseilLocaux() {
        return this.filteConseilLocaux.filter(el => !this.unavailableCLs.includes(el.id));
    },
  },
  watch: {
    dialog(val) {
      if (val === false) {
        this.$validator.pause();
        this.$emit('cancel');
        this.editedAnnee = null;
      }
    },
    annee(val) {
      if (val === undefined || val === null) this.dialog = false;
      else {
        this.editedAnnee = JSON.parse(JSON.stringify(val));
        this.dialog = true;
        this.$validator.resume();
      }
    },
  },
  methods: {
    dontHaveChange() {
      return this.editedAnnee.libelle == this.annee.libelle &&
             this.editedAnnee.date_debut == this.annee.date_debut &&
             this.editedAnnee.date_fin == this.annee.date_fin &&
             this.editedAnnee.date_saisie_debut == this.annee.date_saisie_debut &&
             this.editedAnnee.date_saisie_fin == this.annee.date_saisie_fin;
    },
    hasDates() {
      return this.editedAnnee.date_debut.length > 0 &&
             this.editedAnnee.date_fin.length > 0 &&
             this.editedAnnee.date_saisie_debut.length > 0 &&
             this.editedAnnee.date_saisie_fin.length > 0;
    },
    save() {
      this.dialog = false;
      this.$validator.pause();
      this.$emit('save', this.editedAnnee);
    },
  },
};
</script>